@tailwind base;
@tailwind components;
@tailwind utilities;
@import '~mapbox-gl/dist/mapbox-gl.css';

html, body {

  height: 100%;
  max-width: 100%;
  overflow-x: hidden;
}
body {
  margin: 0; font-family: 'Outfit', "Helvetica Neue", sans-serif;
}

.text-md {
  font-size: 0.9375rem;
  line-height: 1.1875rem;
}

:root {
  --header-min-height: 138px;
  /* --header-max-height: 132px; */
}

.max-padding {
  @apply px-4 ;
  @apply sm:px-[4vw];
}

.center-content-container {
  @apply w-full h-full py-8 max-w-6xl;
  @apply max-padding;
}

.no-content-container {
  @apply flex flex-col justify-center space-y-4 items-center w-full h-full rounded-3xl bg-gray-200 p-6 text-2xl flex-grow my-4 text-gray-900;
  @apply min-h-[25vh];
}

.group-content-container {
  @apply flex flex-col bg-gray-50 border border-zinc-100 rounded-3xl p-6 gap-4;
}


.page-header-title {
  @apply text-3xl font-normal antialiased text-gray-900;
}

.section-header-title {
  @apply text-2xl font-normal antialiased text-gray-900;
}

.subsection-header-title {
  @apply text-xl font-normal antialiased text-gray-900;
}


.parent {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.sheet-shadow {
  box-shadow: 0px -1px 3.5px rgba(0, 0, 0, 0.025);
}

.button-black {
  @apply bg-black  text-white hover:bg-white hover:text-black rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-black;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}

.button-zinc-700 {
  @apply bg-zinc-700  text-white hover:bg-white hover:text-black rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-black;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}
.button-white-zinc-700 {
  /* @apply bg-patron-pink  text-white hover:bg-white hover:text-patron-pink-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-patron-pink ; */
  @apply bg-white  text-zinc-700 hover:bg-zinc-700 hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-zinc-700 hover:border-white cursor-pointer;
}
.icon-white-zinc-700 {
  @apply button-white-zinc-700 flex flex-col justify-center items-center w-10 h-10 corner-25 text-lg;
}

.button-zinc-900 {
  @apply bg-zinc-900  text-white hover:bg-white hover:text-black rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-zinc-900 cursor-pointer;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}
.button-white-zinc-900 {
  /* @apply bg-patron-pink  text-white hover:bg-white hover:text-patron-pink-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-patron-pink ; */
  @apply bg-white  text-zinc-900 hover:bg-zinc-900 hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-zinc-900 hover:border-white cursor-pointer;
}


/* focus:outline-none focus:ring-2 focus:ring-patron-pink-400 focus:ring-offset-2 */
.button-white {
  @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white cursor-pointer;
}
.button-patron-pink {
  @apply bg-patron-pink  text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white cursor-pointer;
  @apply disabled:bg-patron-pink-500 disabled:border-gray-200 disabled:hover:text-white;
  /* @apply hover:bg-white hover:text-patron-pink; */
  /* on hover make it glow */
  @apply hover:shadow-md hover:border-patron-pink;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}
.button-white-patron-pink {
  /* @apply bg-patron-pink  text-white hover:bg-white hover:text-patron-pink-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-patron-pink ; */
  @apply bg-white  text-patron-pink rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-patron-pink cursor-pointer;
  @apply hover:bg-patron-pink-700 hover:text-white hover:border-patron-pink-700;
  /* on hover make it glow */
  @apply hover:shadow-md shadow-patron-pink-900;
}
.button-white-patron-pink-borderless {
  @apply bg-white  text-patron-pink hover:bg-patron-pink hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-none hover:border-white cursor-pointer;
}
.button-white-zinc {
  @apply bg-white  text-zinc-900 hover:bg-zinc-900 hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-zinc-900 hover:border-white cursor-pointer;
}
.button-white-zinc-borderless {
  @apply bg-white  text-zinc-900 hover:bg-zinc-900 hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-none hover:border-white cursor-pointer;
}
.button-patron-pink-borderless {
  @apply bg-patron-pink  text-white hover:bg-white hover:text-patron-pink-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white cursor-pointer;
  @apply disabled:bg-patron-pink-500 disabled:border-gray-200 disabled:hover:text-white disabled:cursor-auto;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}

.button-business-blue {
  @apply bg-business-blue  text-white hover:bg-white hover:text-business-blue rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-business-blue enabled:cursor-pointer disabled:bg-gray-200 disabled:border-white disabled:hover:text-white cursor-pointer;
}
.button-white-business-blue {
  @apply bg-white  text-business-blue hover:bg-business-blue hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-business-blue hover:border-white cursor-pointer;
}
.button-business-blue-borderless {
  @apply bg-business-blue text-white hover:bg-white hover:text-business-blue rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white cursor-pointer;
}

.button-charity-green {
  @apply bg-charity-green  text-white hover:bg-white hover:text-charity-green-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-charity-green cursor-pointer;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}
.button-white-charity-green {
  /* @apply bg-patron-pink  text-white hover:bg-white hover:text-patron-pink-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-patron-pink ; */
  @apply bg-white  text-charity-green hover:bg-charity-green hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-charity-green hover:border-white cursor-pointer;
}
.button-charity-green-borderless {
  @apply bg-charity-green  text-white hover:bg-white hover:text-charity-green-900 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white cursor-pointer;
  /* @apply bg-white text-patron-pink-900 hover:bg-patron-pink hover:border-white hover:text-white rounded py-2 px-4 transition-all duration-300 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white; */
}

.button-red {
  @apply bg-red-700  text-white hover:bg-white hover:text-red-700 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-white hover:border-red-700 enabled:cursor-pointer disabled:bg-gray-200 disabled:border-white disabled:hover:text-white cursor-pointer;
}
.button-white-red {
  @apply bg-white  text-red-700 hover:bg-red-700 hover:text-white rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-red-700 hover:border-white cursor-pointer;
}
.button-red-borderless {
  @apply bg-red-700 text-white hover:bg-white hover:text-red-700 rounded-md py-2 px-4 transition-all duration-150 text-lg sm:text-base font-sans font-normal border border-transparent hover:border-white cursor-pointer;
}

.button-disabled {
  @apply bg-gray-200 text-gray-500 rounded-md py-2 px-4 text-lg sm:text-base font-sans font-normal border border-gray-200 cursor-not-allowed;
}


.corner-25 {
  border-radius: 25px;
}

.loader-wrapper {
  width: 100%;
  height: 100%;
}

.loader {
  @apply border-t-pink;
  animation: spinner 0.9s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@layer utilities {
  .pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
  }
}



.bold-title {
  @apply text-2xl md:text-4xl lg:text-6xl font-semibold antialiased;
}

.bold-subtitle {
  @apply text-xl md:text-2xl lg:text-3xl font-semibold antialiased;
}

.paragraph {
  @apply text-[14pt] md:text-lg;
}

.resp-title {
  @apply text-xl font-bold antialiased text-left leading-normal;
  @apply md:text-[3vw];
  @apply xl:text-[3vw];
  @apply 2xl:text-5xl;
}

.resp-subtitle {
  @apply text-lg font-semibold antialiased text-left leading-normal w-full;
  @apply md:text-[2vw];
  @apply xl:text-[2vw];
  @apply 2xl:text-2xl;
}

.resp-label {
  @apply text-base text-left leading-normal w-full;
  @apply md:text-[1.9vw];
  @apply lg:text-[1.8vw];
  @apply 2xl:text-2xl;
}

.resp-para {
  @apply text-base text-left leading-normal w-full;
  @apply md:text-[1.85vw];
  @apply lg:text-[1.5vw];
  @apply 2xl:text-xl;
}


.back-shadow {
  @apply relative;
}

.back-shadow-bg {
  @apply absolute -inset-1 rounded-lg bg-gradient-to-r from-white via-white to-white opacity-40 blur-sm;
}
.back-shadow-bg-dark {
  @apply absolute -inset-1 rounded-lg bg-gradient-to-r from-black via-black to-black opacity-5 blur-sm;
}
.back-shadow-contents {
  @apply relative flex items-center justify-center bg-none;
}

.body-grabbing {
  @apply cursor-grabbing;
}

/** Loading Utils **/
@keyframes pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: pulse 1.5s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes shimmer {
  0% {
    transform: translateX(-100%) skewX(-20deg); /* Start from the left and skew */
  }
  100% {
    transform: translateX(100%) skewX(-20deg); /* Move to the right and skew */
  }
}

.shimmer {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-20deg);
}

.shimmer-light-gray {
  animation: shimmer 2s infinite linear;
  background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 50%, rgba(255, 255, 255, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: skewX(-20deg);
}

.shimmer-container-light-gray {
  @apply bg-gray-300 w-full rounded relative overflow-hidden;
}
.shimmer-container-light-gray-h-6 {
  @apply bg-gray-300 h-6 w-full rounded relative overflow-hidden;
}
.shimmer-container-light-gray-h-7 {
  @apply bg-gray-300 h-7 w-full rounded relative overflow-hidden;
}
.shimmer-container-light-gray-h-full {
  @apply bg-gray-300 h-full w-full rounded relative overflow-hidden;
}



/** Mapbox styles required in the global stylesheet in order for the map to work **/
.zeal-marker {
  background-image: url('/assets/MapPinPink.svg');
  z-index: -1;
  @apply bg-center bg-no-repeat bg-contain cursor-pointer;
  width: 40px;
  height: 40px;
  border-radius: 0;
  cursor: pointer;
}

.zeal-marker-charity {
  background-image: url('/assets/MapPinGreen.svg');
  z-index: -1;
  @apply bg-center bg-no-repeat bg-contain cursor-pointer;
  width: 40px;
  height: 40px;
  border-radius: 0;
  cursor: pointer;
}

.zeal-highlighted {
  background-image: none;
  z-index: 50;
  @apply w-12 h-12 bg-patron-pink rounded-full cursor-pointer;
  @apply flex justify-center items-center;
  @apply text-center text-opacity-95 text-xl font-bold leading-loose text-white;
}
.zeal-highlighted-charity {
  background-image: none;
  z-index: 50;
  @apply w-12 h-12 bg-charity-green rounded-full cursor-pointer;
  @apply flex justify-center items-center;
  @apply text-center text-opacity-95 text-xl font-bold leading-loose text-white;
}

/* Mapbox Marker tweaks */
.mapboxgl-popup {
  padding-bottom: 50px;
}

.mapboxgl-popup-close-button {
  display: none;
}
.mapboxgl-popup-content {
  padding: 0;
  width: 220px;
}
.mapboxgl-popup-content h3 {
  @apply bg-pink;
  color: #fff;
  margin: 0;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
}

.mapboxgl-popup-content h3.charity {
  @apply bg-charity-green;
  color: #fff;
  margin: 0;
  padding: 10px;
  border-radius: 3px 3px 0 0;
  font-weight: 700;
}

.mapboxgl-popup-content h4 {
  margin: 0;
  padding: 10px;
  font-weight: 400;
}

.mapboxgl-popup-tip {
  display: none;
}

.gz-bg-gradient {
  @apply bg-gradient-to-t from-stone-200 to-stone-100;
}
.gz-bg-default {
  @apply bg-stone-200;
}


/** NGX Slick Carousel **/
.slick-prev:before {
  /* @apply bg-gray-800;
  -webkit-mask: url('/assets/icons/right_arrow.svg') no-repeat 50% 50% !important;
  mask: url('/assets/icons/right_arrow.svg') no-repeat 50% 50% !important;
  transform: rotate(180deg); */
}
.slick-next:before {
  /* background-color: #ff0000; */
  /* background-image: url('/assets/icons/right_arrow.svg') !important; */
  /* @apply bg-gray-800;
  -webkit-mask: url('/assets/icons/right_arrow.svg') no-repeat 50% 50% !important;
  mask: url('/assets/icons/right_arrow.svg') no-repeat 50% 50% !important; */
}
